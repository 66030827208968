import axios from 'axios';

export const getSpeakers = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/speakers.php`)
    return data;
}

export const getNationals = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/nationals.php`)
    return data;
}

export const getDailys = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/dailys.php`)
    return data;
}