import React, { useEffect, useState } from 'react';
import './national.scss';
// import swiper library
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import "swiper/css";
// import required modules
import { Autoplay } from "swiper";
import { getNationals } from '../../utilities/sendRequest';

const National = () => {
    const [listEvents, setListEvents] = useState([])

    useEffect(() => {
        fetchEvents();
      }, [])

    const fetchEvents = () => {
        getNationals().then((response) => {
            setListEvents(response.payload)
        }).catch((error) => {
            console.log(error.message);
        })
    }

    const handleRegis = (linkURL) => {
        // window.location.href = linkURL;
        window.open(linkURL, '_blank');
    }

    return (
        <>
        { (listEvents.length>0) ? 
                <div className='containerNational' id='event' data-aos="fade-in" data-aos-delay="300">
                    <h1 className='title'>National Event</h1>
                    <div className='row'>
                        <div className='sideLeft'></div>
                        <div className='sideRight'>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={1}
                                centeredSlides={false}
                                slidesPerGroup={1}
                                grabCursor={true}
                                autoplay={false}
                                modules={[Autoplay]}
                                breakpoints={{
                                    769: {
                                        centeredSlides: false,
                                        slidesPerView: 1,
                                        spaceBetween: 1,
                                        slidesPerGroup: 1,
                                    },
                                    993: {
                                        centeredSlides: false,
                                        slidesPerView: 2,
                                        spaceBetween: 2,
                                        slidesPerGroup: 1,
                                    },
                                }}
                                className='rowSlider'
                            >
                                { listEvents.map((event) => (
                                    <SwiperSlide className='boxEvent' key={event.id}>
                                        <img className='boxPoster' src={`${process.env.REACT_APP_PUBLIC_FOLDER_FLYERS}/${event.photo}`} alt='Flyer' onClick={() => handleRegis(event.link)}/>
                                        <div className='boxRegis'>
                                            <a className='buttonRegis' href={ event.link }>Registrasi</a>
                                        </div>
                                        <div className='boxDesc'>
                                            <span className='eventTitle'>{ event.name }</span>
                                            <div className='eventType'>
                                                {event.types.map((type, index) =>(
                                                    <span key={index} className='nameType'>{ type.type }</span>
                                                ))}
                                            </div>
                                            <div className='eventDesc'>
                                                <img className='descIcon' src='/assets/time-icon-black.svg' alt='Icon'/>
                                                <div className='descInfo'>
                                                    <span className='detailTitle'>{ event.schedule }</span>
                                                    <span className='detailDesc'>{ event.detail_schedule }</span>
                                                </div>
                                            </div>
                                            <div className='eventDesc'>
                                                <img className='descIcon' src='/assets/location-icon-black.svg' alt='Icon'/>
                                                <div className='descInfo'>
                                                    <span className='detailTitle'>{ event.location }</span>
                                                    <span className='detailDesc'>{ event.detail_location }</span>
                                                </div>
                                            </div>
                                            <div className='eventDesc'>
                                                <img className='descIcon' src='/assets/info-icon-black.svg' alt='Icon'/>
                                                <div className='descInfo'>
                                                    <span className='detailTitle'>{ event.information }</span>
                                                    <span className='detailDesc'>
                                                        { event.detail_information }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='eventRegis'>
                                                <a className='buttonRegis' href={ event.link }>Registrasi</a>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                {/* buat pengkondisian jika ada lebih dari 1 aktifkan dibawah ini (berfungsi sebagai space slider)*/}
                                { listEvents.length > 1 && 
                                    <>
                                        <SwiperSlide></SwiperSlide>
                                        <SwiperSlide></SwiperSlide>
                                    </>
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            : 
                ""
        }
        </>
    )
}

export default National