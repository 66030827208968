import React, { useState } from 'react';
import './event.scss';
import Popup from '../popup/Popup';
import moment from 'moment';
import 'moment/locale/id'; // Impor lokalisasi bahasa Indonesia

const Event = ({ event }) => {
  const [modalView, setModalView] = useState(false)
  moment.locale('id');

  const handleView = () => {
    setModalView(current => !current)
  }

  return (
    <>
      {modalView ? <Popup pathImg={`${process.env.REACT_APP_PUBLIC_FOLDER_FLYERS}/${event.photo}`} setModalView={setModalView}  /> : null}
      <div className='container' >
        <div className='eventTitle'>
          <span className='titleEvent'>{ event.name }</span>
          <div className='typeEvent'>
            { event.types.map((type, i) => (
              <div key={ i } className='nameType'>{ type.type }</div>
            ))} 
          </div>
        </div>
        <hr className='hrEvent'/>
        <div className='eventDesc'>
          <img className='eventImg' src={`${process.env.REACT_APP_PUBLIC_FOLDER_FLYERS}/${event.photo}`} alt='Flyer' onClick={handleView}/>
          {/* <img className='eventImgShadow' src='/assets/flyer.jpeg' alt='Shadow' /> */}
          <div className='eventInfo'>
            <div className='infoLeft'>
              <div className='info'>
                <img className='infoIcon' src='/assets/time-icon-white.svg' alt='Icon' />
                <div className='infoDetails'>
                  <span className='detailTitle'>{ moment(event.schedule_date).format("D MMMM YYYY") }</span>
                  <span className='detailDesc'>{ event.schedule_time }</span>
                </div>
              </div>
              <div className='info'>
                <img className='infoIcon' src='/assets/location-icon-white.svg' alt='Icon' />
                <div className='infoDetails'>
                  <span className='detailTitle'>{ event.location }</span>
                  <span className='detailDesc'>{ event.detail_location }</span>
                </div>
              </div>
            </div>
            <div className='infoRight'>
              <div className='info'>
                <img className='infoIcon' src='/assets/speaker-icon-white.svg' alt='Icon' />
                <div className='infoDetails'>
                  <span className='detailTitle'>{ event.speaker }</span>
                  <span className='detailDesc'>
                    { event.speaker_level }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='eventButton'>
          { event.link ? <a href={ event.link } className='btnCheck'>JOIN EVENT</a> : null }
        </div>
      </div>    
    </>
  )
}

export default Event