import React from 'react';
import './footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='footer'>
      <span className='copyright'>Copyrigt @ { currentYear }</span>
    </div>
  )
}

export default Footer