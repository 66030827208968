import { useEffect } from "react";
import Topbar from "./components/topbar/Topbar";
import Hero from "./components/hero/Hero";
import Events from "./components/events/Events";
import Footer from "./components/footer/Footer";
// import aos library & styles
import AOS from 'aos';
import 'aos/dist/aos.css';
import './app.scss';
import National from "./components/nationals/National";

function App() {

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <div className="app">
      <Topbar />
      <div className="sections">
        <Hero />
        <National />
        <div className="lineEvent">
          <hr className='hrEvent'/>
        </div>
        <Events />
        <Footer />
      </div>
    </div>
  );
}

export default App;
