import React, { useEffect, useState } from 'react';
import './events.scss';
import Event from '../event/Event';
import { generateDateRange } from '../../utilities/dateRange';
import { getDailys } from '../../utilities/sendRequest';

const Events = () => {
  const rangeDate = generateDateRange()
  const [active, setActive] = useState(0)
  const [listEvents, setListEvents] = useState([])
  const [events, setEvents] = useState([])

  useEffect(() => {
    fetchDailys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchDailys = () => {
    getDailys().then((response) => {
      setListEvents(response.payload)
      filterEvents(rangeDate[0].yyyymmdd, response.payload)
    }).catch((error) => {
      console.log(error.message);
    })
  }

  const handleActiveEvent = (index, date) => {
    setActive(index)
    filterEvents(date.yyyymmdd, listEvents)
  }

  const filterEvents = (date, datas) => {
    const filteredData = datas.filter(item => item.schedule_date === date )
    // Mengurutkan data berdasarkan schedule_time secara ascending
    filteredData.sort((a, b) => {
      // Menggunakan localeCompare untuk membandingkan string waktu
      return a.schedule_time.localeCompare(b.schedule_time);
    });
    setEvents(filteredData);
    
    // setEvents(datas.filter(item => item.schedule_date === date ))
  }

  return (
    <div className='event' data-aos="fade-in" data-aos-delay="300">
      <h1 className='title'>Daily Event</h1>

      <div className='containerDate'>
        {rangeDate.map((date, i) => (
            <div key={i} className={`boxDate ${(active === i) && 'active'}`} onClick={() => handleActiveEvent(i, date)}>
              <h3 className='date'>{date.dd}</h3>
              <span className='month'>{date.mm}</span>
            </div>
        ))}
      </div>
      { events.length > 0 ? 
        <>
          { events.map((event, i) => (
              <Event key={i} event={event} />
          ))}
        </>
        : <div className='noEvents'>Tidak ada event</div>
      }
    </div>
  )
}

export default Events
