
import moment from 'moment';
import 'moment/locale/id'; // Impor lokalisasi bahasa Indonesia

export const generateDateRange = () => {
    moment.locale('id')
    const dates = [];
    const startDate = moment();
    const endDate = moment().add(10, 'days');
  
    while (startDate.isSameOrBefore(endDate, 'day')) {
        const data = {
            dd: startDate.format('DD'),
            mm: startDate.format('MMM'),
            yyyymmdd: startDate.format('YYYY-MM-DD')
        }
      dates.push(data);
      startDate.add(1, 'day');
    }
  
    return dates;
};
  