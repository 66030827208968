import React from 'react';
import './popup.scss';

const Popup = ({ pathImg, setModalView }) => {

  const handleModal = () => {
    setModalView(current => !current)
  }

  return (
    <>
      <div className='containerPopup'>
        <div className='rowPopup'>
					<div className='colTitle'>
						<img src='assets/close-icon.svg' alt="Icon Close" className='imgIcon' onClick={handleModal} />
					</div>
          <div className='colImg'>
            <img src={pathImg} alt="Detail Event" className='imgPopup' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Popup