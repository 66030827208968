import React from 'react';
import Mentor from "../mentor/Mentor";
import './hero.scss';

const Hero = () => {
  return (
    <div className='hero' id='hero'>
        <div className='top' data-aos="fade-in" data-aos-delay="100">
          <div className='topHero' data-aos="fade-in" data-aos-delay="200">
            <span className='textTitleHero'>
              Build Your Future With Us
            </span>
            <span className='textSubTitleHero'>
              Mengembangkan diri bersama mentor-mentor terbaik di HDI
            </span>
          </div>
          <div className='botHero' data-aos="fade-in" data-aos-delay="300">
            <a href='#event' className='btnEvent'>DAFTAR SEKARANG</a>
            <span className='textBotHero'>
              1000+ Orang telah mengikuti
            </span>
          </div>
        </div>
        <div className='bottom'>
          <Mentor />
        </div>
    </div>
  )
}

export default Hero 