import React, { useEffect, useState } from 'react';
import './mentor.scss';
// import swiper library
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import "swiper/css";
// import required modules
import { Autoplay } from "swiper";
import { getSpeakers } from '../../utilities/sendRequest';

const Mentor = () => {
  const [listSpeakers, setListSpeakers] = useState([])

  useEffect(() => {
    fetchSpeakers()
  }, [])

  const fetchSpeakers = async () => {
    getSpeakers().then((response) => {
      setListSpeakers(response.payload)
    }).catch((error) => {
      console.log(error.message);
    })
  }
  return (
     <div className='mentor' data-aos="fade-in" data-aos-delay="300">
      <h1 className='titleMentor'>Our Speaker</h1>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        centeredSlides={false}
        slidesPerGroup={1}
        grabCursor={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          769: {
              centeredSlides: false,
              slidesPerView: 3,
              spaceBetween: 15,
              slidesPerGroup: 1,
          },
          993: {
              centeredSlides: false,
              slidesPerView: 4,
              spaceBetween: 10,
              slidesPerGroup: 1,
          },
        }}
        className='row'
      >
        { listSpeakers.map((speaker) => (
          <SwiperSlide key={speaker.id} className='col'>
            <div className='imgContainer'>
              <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_SPEAKERS}/${speaker.photo}`} alt={ speaker.name} className='photo' />
            </div>
            <h5 className='nameMentor'>{ speaker.name }</h5>
            <span className='titleMentor'>{ speaker.level }</span>
          </SwiperSlide>
        ))}
        <SwiperSlide></SwiperSlide>
      </Swiper>
     </div>
  )
}

export default Mentor