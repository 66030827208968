import React from 'react';
import './topbar.scss';

const Topbar = () => {
  return (
    <div className='topbar'>
      <div className='wrapper'>
        <div className='left'>
          <a href='#hero' className='logo'>BRAND CENTER SURABAYA</a>
        </div>
      </div>
    </div>
  )
}

export default Topbar